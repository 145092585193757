












































import { Vue, Component, Watch, PropSync, Ref } from 'vue-property-decorator'
import { Fornecedor, Page } from '@/models'
import { FindFornecedorUseCase } from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import { Pageable } from '@/models/Pageable'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { formatarCnpjOuCpf } from '@/shareds/formatadores'
@Component
export default class SeletorDeFornecedorComBusca extends Vue {
	@PropSync('value', { default: null }) fornecedor!: Fornecedor | string
	findFornecedorUseCase = new FindFornecedorUseCase()
	busca: null | string = null
	pagina: Page<Fornecedor> | null = null
	fornecedores: Fornecedor[] = []
	fornecedoresCarregados = 0
	carregouTodosOsFornecedores = false
	carregando = false
	cancelToken: CancelTokenSource | null = null
	@Ref() campoDeSelecaoDeFornecedor!: HTMLInputElement
	formatarCnpjOuCpf = formatarCnpjOuCpf

	async buscar(paginavel: Pageable = {}) {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			this.pagina = await this.findFornecedorUseCase.find({
				page: this.busca ? (paginavel.page || 0) : 0,
				size: paginavel.size || 20,
				busca: this.busca,
			}, axiosConfig)
			
			this.pagina.content.forEach(fornecedor => {
				this.fornecedores.push(fornecedor)
			})

			this.fornecedoresCarregados = this.fornecedores.length
			this.carregouTodosOsFornecedores = this.fornecedoresCarregados >= this.pagina.totalElements
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}

	realizarBusca(busca: string | Fornecedor) {
		if (typeof busca === 'string') {
			this.busca = busca

			const termoBusca = busca.toLowerCase()
			this.fornecedores = this.fornecedores.filter((fornecedor) => {
				const nome = fornecedor.razaoSocialOuNome.toLowerCase();
				const cpf = fornecedor.cnpjOuCpf.toLowerCase();
				return nome.includes(termoBusca) || cpf.includes(termoBusca);
			})
		}
	}

	@Watch('busca')
	onChangeBusca() {
		this.fornecedoresCarregados = 0
		this.fornecedores = []
		this.carregouTodosOsFornecedores = false
		this.buscar()
		this.pagina = null
	}

	limpaFiltros() {
		this.carregouTodosOsFornecedores = false
		this.fornecedoresCarregados = 0
	}

	focus() {
		this.campoDeSelecaoDeFornecedor.focus()
	}
}
