import { render, staticRenderFns } from "./SeletorDeFornecedorComBusca.vue?vue&type=template&id=0f5a70d9&scoped=true&"
import script from "./SeletorDeFornecedorComBusca.vue?vue&type=script&lang=ts&"
export * from "./SeletorDeFornecedorComBusca.vue?vue&type=script&lang=ts&"
import style0 from "./SeletorDeFornecedorComBusca.vue?vue&type=style&index=0&id=0f5a70d9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f5a70d9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCombobox,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle})
